<template>
  <div>
    <a-form :form="form" @submit="handleSubmit">
      <a-row :gutter="[0, 0]">
        <a-col class="mb-20" :span="24">
          <a-form-item class="mb-0">
            <a-radio-group
              v-decorator="[
                'person_type',
                {
                  rules: [
                    {
                      required: true,
                      message: 'Selecione qual o tipo de pessoa.',
                    },
                  ],
                },
              ]"
              :options="personTypes"
          /></a-form-item>
        </a-col>
        <a-row
          :gutter="20"
          v-if="form.getFieldValue(`person_type`) == 'Pessoa Física'"
        >
          <a-col :span="12">
            <a-form-item class="travel-input-outer">
              <label
                for
                :class="form.getFieldValue(`first_name`) ? 'filled' : ''"
                >Nome</label
              >
              <a-input
                class="travel-input"
                :placeholder="`Nome`"
                v-decorator="[
                  `first_name`,
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Obrigatório',
                      },
                    ],
                  },
                ]"
              >
                <a-icon slot="prefix" type="field-svg" />
              </a-input>
            </a-form-item>
          </a-col>

          <a-col :span="12">
            <a-form-item class="travel-input-outer">
              <label
                for
                :class="form.getFieldValue(`last_name`) ? 'filled' : ''"
                >Sobrenome</label
              >
              <a-input
                class="travel-input"
                :placeholder="`Sobrenome`"
                v-decorator="[
                  `last_name`,
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Obrigatório',
                      },
                    ],
                  },
                ]"
              >
                <a-icon slot="prefix" type="field-svg" />
              </a-input>
            </a-form-item>
          </a-col>

          <a-col :span="7">
            <a-form-item class="travel-input-outer">
              <label for :class="form.getFieldValue(`cpf`) ? 'filled' : ''"
                >CPF</label
              >
              <a-input
                class="travel-input"
                :placeholder="`CPF`"
                v-mask="'###.###.###-##'"
                v-decorator="[
                  `cpf`,
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Obrigatório',
                      },
                    ],
                  },
                ]"
              >
                <a-icon slot="prefix" type="field-svg" />
              </a-input>
            </a-form-item>
          </a-col>

          <a-col :span="6">
            <a-form-item class="travel-input-outer">
              <label for :class="this.form.getFieldValue('rg') ? 'filled' : ''"
                >RG</label
              >
              <a-input
                class="travel-input"
                placeholder="RG"
                v-mask="['##.###.###', '#.###.###', '###.###.###-##']"
                v-decorator="[
                  'rg',
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Obrigatório',
                      },
                      {
                        min: 9,
                        message: 'Preencha todos números',
                      },
                    ],
                  },
                ]"
              >
                <a-icon slot="prefix" type="field-svg" />
              </a-input>
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item class="travel-input-outer">
              <label
                for
                :class="this.form.getFieldValue('doc_emission') ? 'filled' : ''"
                >Órgão Emissor</label
              >
              <a-input
                class="travel-input"
                type="text"
                placeholder="Órg. Emissor"
                v-decorator="[
                  'doc_emission',
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Obrigatório',
                      },
                    ],
                  },
                ]"
              >
                <a-icon slot="prefix" type="field-svg" />
              </a-input>
            </a-form-item>
          </a-col>

          <a-col :span="5">
            <a-form-item class="travel-input-outer">
              <label
                for
                :class="
                  this.form.getFieldValue('doc_emission_state') ? 'filled' : ''
                "
                >ES</label
              >
              <a-select
                class="travel-input"
                show-search
                placeholder="ES"
                v-decorator="[
                  'doc_emission_state',
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Obrigatório',
                      },
                    ],
                  },
                ]"
              >
                <a-select-option
                  v-for="(item, index) of states"
                  :key="index"
                  :value="item.initials"
                >
                  {{ item.initials.toUpperCase() }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :span="7">
            <a-form-item class="travel-input-outer">
              <label
                for
                :class="this.form.getFieldValue('birthday') ? 'filled' : ''"
                >Nascimento</label
              >
              <a-date-picker
                class="travel-input"
                placeholder="Nascimento"
                format="DD/MM/YYYY"
                v-mask="'##/##/####'"
                :showToday="false"
                :disabled-date="adultHoodDate"
                @openChange="defaultDate"
                v-decorator="[
                  'birthday',
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Obrigatório',
                      },
                    ],
                  },
                ]"
              >
              </a-date-picker>
            </a-form-item>
          </a-col>
        </a-row>

        <a-row
          :gutter="20"
          v-if="form.getFieldValue(`person_type`) == 'Pessoa Jurídica'"
        >
          <a-col :span="24">
            <a-form-item class="travel-input-outer">
              <label
                for
                :class="form.getFieldValue(`company_name`) ? 'filled' : ''"
                >Razão Social</label
              >
              <a-input
                class="travel-input"
                :placeholder="`Razão Social`"
                v-decorator="[
                  `company_name`,
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Obrigatório',
                      },
                    ],
                  },
                ]"
              >
                <a-icon slot="prefix" type="field-svg" />
              </a-input>
            </a-form-item>
          </a-col>

          <a-col :span="12">
            <a-form-item class="travel-input-outer">
              <label
                for
                :class="form.getFieldValue(`trading_name`) ? 'filled' : ''"
                >Nome Fantasia</label
              >
              <a-input
                class="travel-input"
                placeholder="Nome Fantasia"
                v-decorator="[
                  `trading_name`,
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Obrigatório',
                      },
                    ],
                  },
                ]"
              >
                <a-icon slot="prefix" type="field-svg" />
              </a-input>
            </a-form-item>
          </a-col>

          <a-col :span="12">
            <a-form-item class="travel-input-outer">
              <label for :class="form.getFieldValue(`cnpj`) ? 'filled' : ''"
                >CNPJ</label
              >
              <a-input
                class="travel-input"
                placeholder="CNPJ"
                v-mask="'##.###.###/####-##'"
                v-decorator="[
                  `cnpj`,
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Obrigatório',
                      },
                    ],
                  },
                ]"
              >
                <a-icon slot="prefix" type="field-svg" />
              </a-input>
            </a-form-item>
          </a-col>
        </a-row>

        <a-divider orientation="left">Endereço</a-divider>

        <a-row :gutter="20">
          <a-col :span="7">
            <a-form-item class="travel-input-outer">
              <label for :class="this.form.getFieldValue('cep') ? 'filled' : ''"
                >CEP</label
              >
              <a-input
                class="travel-input"
                placeholder="CEP"
                v-mask="'#####-###'"
                v-decorator="[
                  'cep',
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Obrigatório',
                      },
                    ],
                  },
                ]"
                @keyup="getLocation()"
              >
                <a-icon slot="prefix" type="field-svg" />

                <a-icon v-if="cepLoading" slot="suffix" type="loading" />
              </a-input>
            </a-form-item>
          </a-col>
          <a-col :span="17">
            <a-form-item class="travel-input-outer">
              <label
                for
                :class="
                  this.form.getFieldValue('location_street') ? 'filled' : ''
                "
                >Logradouro</label
              >
              <a-input
                class="travel-input"
                placeholder="Logradouro"
                v-decorator="[
                  'location_street',
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Obrigatório',
                      },
                    ],
                  },
                ]"
              >
                <a-icon slot="prefix" type="field-svg" />
              </a-input>
            </a-form-item>
          </a-col>
          <a-col :span="7">
            <a-form-item class="travel-input-outer">
              <label
                for
                :class="
                  this.form.getFieldValue('location_number') ? 'filled' : ''
                "
                >Número</label
              >
              <a-input
                type="number"
                class="travel-input"
                placeholder="Número"
                v-decorator="[
                  'location_number',
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Obrigatório',
                      },
                    ],
                  },
                ]"
              >
                <a-icon slot="prefix" type="field-svg" />
              </a-input>
            </a-form-item>
          </a-col>

          <a-col :span="17">
            <a-form-item class="travel-input-outer">
              <label
                for
                :class="
                  this.form.getFieldValue('location_complement') ? 'filled' : ''
                "
                >Complemento</label
              >
              <a-input
                class="travel-input"
                placeholder="Complemento"
                v-decorator="['location_complement']"
              >
                <a-icon slot="prefix" type="field-svg" />
              </a-input>
            </a-form-item>
          </a-col>
          <a-col :span="9">
            <a-form-item class="travel-input-outer">
              <label
                for
                :class="
                  this.form.getFieldValue('location_neighborhood')
                    ? 'filled'
                    : ''
                "
                >Bairro</label
              >
              <a-input
                class="travel-input"
                placeholder="Bairro"
                v-decorator="[
                  'location_neighborhood',
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Obrigatório',
                      },
                    ],
                  },
                ]"
              >
                <a-icon slot="prefix" type="field-svg" />
              </a-input>
            </a-form-item>
          </a-col>
          <a-col :span="9">
            <a-form-item class="travel-input-outer">
              <label
                for
                :class="
                  this.form.getFieldValue('location_city') ? 'filled' : ''
                "
                >Cidade</label
              >
              <a-input
                class="travel-input"
                placeholder="Cidade"
                v-decorator="[
                  'location_city',
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Obrigatório',
                      },
                    ],
                  },
                ]"
              >
                <a-icon slot="prefix" type="field-svg" />
              </a-input>
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item class="travel-input-outer">
              <label
                for
                :class="
                  this.form.getFieldValue('location_state') ? 'filled' : ''
                "
                >Estado</label
              >
              <a-select
                class="travel-input"
                show-search
                placeholder="ES"
                v-decorator="[
                  'location_state',
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Obrigatório',
                      },
                    ],
                  },
                ]"
              >
                <a-select-option
                  v-for="(item, index) of states"
                  :key="index"
                  :value="item.initials"
                >
                  {{ item.initials.toUpperCase() }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>

        <a-divider orientation="left">Dados do cartão</a-divider>

        <a-row :gutter="20">
          <a-col :span="12">
            <a-form-item class="travel-input-outer">
              <label for :class="form.getFieldValue('bank_id') ? 'filled' : ''"
                >Estado</label
              >
              <a-select
                class="travel-input"
                show-search
                placeholder="Banco"
                v-decorator="[
                  'bank_id',
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Obrigatório',
                      },
                    ],
                  },
                ]"
              >
                <a-select-option
                  v-for="(item, index) of banks"
                  :key="index"
                  :value="item.id"
                >
                  {{ item.numero }} - {{ item.nome }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :span="12">
            <a-form-item class="travel-input-outer">
              <label
                for
                :class="
                  this.form.getFieldValue('location_street') ? 'filled' : ''
                "
                >Nome no cartão</label
              >
              <a-input
                class="travel-input"
                placeholder="Nome no cartão"
                v-decorator="[
                  'card_name',
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Obrigatório',
                      },
                    ],
                  },
                ]"
              >
                <a-icon slot="prefix" type="field-svg" />
              </a-input>
            </a-form-item>
          </a-col>

          <a-col :span="12">
            <a-form-item class="travel-input-outer">
              <label
                for
                :class="form.getFieldValue('card_number') ? 'filled' : ''"
                >Número do cartão</label
              >
              <a-input
                class="travel-input"
                placeholder="Número do cartão"
                v-mask="'#### #### #### ####'"
                v-decorator="[
                  'card_number',
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Obrigatório',
                      },
                    ],
                  },
                ]"
              >
                <a-icon slot="prefix" type="field-svg" />
              </a-input>
            </a-form-item>
          </a-col>

          <a-col :span="6">
            <a-form-item class="travel-input-outer">
              <label
                for
                :class="form.getFieldValue(`card_month`) ? 'filled' : ''"
                >Mês de Expiração</label
              >
              <a-select
                class="travel-input select"
                placeholder="Mês de Expiração"
                v-decorator="[
                  `card_month`,
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Obrigatório.',
                      },
                    ],
                  },
                ]"
              >
                <a-select-option
                  v-for="(item, index) of cardMonths"
                  :key="index"
                  :value="item"
                >
                  {{ item }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :span="6">
            <a-form-item class="travel-input-outer">
              <label
                for
                :class="form.getFieldValue(`card_year`) ? 'filled' : ''"
                >Ano de Expiração</label
              >
              <a-select
                class="travel-input select"
                placeholder="Ano"
                v-decorator="[
                  `card_year`,
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Preencha todos números.',
                      },
                    ],
                  },
                ]"
              >
                <a-select-option
                  v-for="(item, index) of cardYears"
                  :key="index"
                  :value="item"
                >
                  {{ item }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :span="6">
            <a-form-item class="travel-input-outer">
              <label for :class="form.getFieldValue('card_cvv') ? 'filled' : ''"
                >CVV</label
              >
              <a-input
                class="travel-input"
                placeholder="CVV"
                v-decorator="[
                  'card_cvv',
                  {
                    rules: [
                      {
                        required: false,
                        message: 'Obrigatório',
                      },
                    ],
                  },
                ]"
              >
                <a-icon slot="prefix" type="field-svg" />
              </a-input>
            </a-form-item>
          </a-col>

          <a-col :span="12">
            <a-form-item class="travel-input-outer">
              <label
                for
                :class="form.getFieldValue('card_limit') ? 'filled' : ''"
                >Limite do cartão (R$)</label
              >
              <a-input
                class="travel-input"
                v-currency
                placeholder="Limite do cartão (R$)"
                v-decorator="[
                  'card_limit',
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Obrigatório',
                      },
                    ],
                  },
                ]"
              >
                <a-icon slot="prefix" type="field-svg" />
              </a-input>
            </a-form-item>
          </a-col>
        </a-row>

        <a-col class="a-center mt-20" :span="24">
          <a-button v-if="!edit" size="large" type="primary" html-type="submit">
            CRIAR CARTÃO
          </a-button>
          <a-button v-if="edit" size="large" type="primary" html-type="submit">
            ATUALIZAR DADOS DO CARTÃO
          </a-button>
        </a-col>
      </a-row>

      <!-- {{cardId}} -->
      <!--
            {{ company_credit_cards }} -->
    </a-form>
  </div>
</template>

<script>
import { format } from "date-fns";
import states from "../../json/states.json";
import banks from "../../json/banks.json";
import axios from "axios";
import subYears from "date-fns/subYears";

export default {
  props: {
    company_credit_cards: Array,
    edit: Boolean,
    cardId: Number,
  },
  data() {
    return {
      form: this.$form.createForm(this),
      states,
      banks,
      personTypes: [
        { label: "Pessoa Física", value: "Pessoa Física" },
        { label: "Pessoa Jurídica", value: "Pessoa Jurídica" },
      ],
      cardMonths: [
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
      ],
      cardYears: [
        "2021",
        "2022",
        "2023",
        "2024",
        "2025",
        "2026",
        "2027",
        "2028",
        "2029",
        "2030",
        "2031",
        "2032",
        "2033",
        "2034",
        "2035",
        "2036",
        "2037",
        "2038",
        "2039",
        "2040",
      ],
    };
  },
  mounted() {
    if (!this.edit) {
      setTimeout(() => {
        this.form.setFieldsValue({
          person_type: "Pessoa Física",
        });
      }, 100);
    }

    if (this.edit) {
      this.company_credit_cards.forEach((card) => {
        if (card.id == this.cardId) {
          setTimeout(() => {
            this.form.setFieldsValue({
              person_type: card.person_type,
            });

            this.form.setFieldsValue({
              id: card.id,

              card_number: card.card_number,
              card_name: card.card_name,
              card_year: card.card_year,
              card_month: card.card_month,
              card_cvv: card.card_cvv,
              card_limit: card.card_limit,
              cep: card.cep,
              location_street: card.location_street,
              location_number: card.location_number,
              location_complement: card.location_complement,
              location_neighborhood: card.location_neighborhood,
              location_city: card.location_city,
              location_state: card.location_state,
            });

            setTimeout(() => {
              this.form.setFieldsValue({
                cnpj: card.cnpj,
                bank_id: card.bank_id,
                trading_name: card.trading_name,
                company_name: card.company_name,
                cpf: card.cpf,
                rg: card.rg,
                doc_emission_state: card.doc_emission_state,
                doc_emission: card.doc_emission,
                birthday: card.birthday,
                first_name: card.first_name,
                last_name: card.last_name,
              });
            }, 300);
          }, 100);
        }
      });
    }
  },
  methods: {
    adultHoodDate(current) {
      return current && current > subYears(new Date(), 18);
    },
    defaultDate() {
      setTimeout(() => {
        this.form.setFieldsValue({
          birthday: this.form.getFieldValue("birthday")
            ? this.form.getFieldValue("birthday")
            : format(subYears(new Date(), 18), "MM/dd/yyyy"),
        });
      }, 1);
    },
    getLocation() {
      setTimeout(() => {
        if (this.form.getFieldValue("cep")) {
          console.log(this.form.getFieldValue("cep").replace("-", ""));

          if (this.form.getFieldValue("cep").replace("-", "").length == 8) {
            this.cepLoading = true;
            axios
              .get(
                "https://viacep.com.br/ws/" +
                  this.form.getFieldValue("cep") +
                  "/json/"
              )
              .then(({ data }) => {
                data;
                // this.$message.success("Sucesso!");
                this.cepLoading = false;
                this.form.setFieldsValue({
                  location_street: data.logradouro.toUpperCase(),
                  location_state: data.uf,
                  location_city: data.localidade.toUpperCase(),
                  location_neighborhood: data.bairro.toUpperCase(),
                });
              })
              .catch(({ response }) => {
                response;
                this.$message.error(
                  "Houve um problema ao recuperar a localização."
                );
                this.cepLoading = false;
              });
          }
        }
      }, 110);
    },
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        console.log(err, values);

        if (!this.edit) {
          if (!err) {
            this.loading = true;

            let max = 0;
            this.company_credit_cards.forEach((card) => {
              if (card.id > max) {
                max = card.id;
              }
            });

            this.company_credit_cards.push({
              id: max + 1,
              ...values,
            });

            console.log("Received values of form: ", this.company_credit_cards);

            this.$http
              .post("/option/update", {
                id: 8,
                value: JSON.stringify(this.company_credit_cards),
              })
              .then(({ data }) => {
                data;
                this.$message.success("Atualizado com sucesso!");
                this.loading = false;
              })
              .catch(({ res }) => {
                res;
                this.$message.error("Não foi possível atualizar.");
                this.loading = false;
              });
          }
        }

        if (this.edit) {
          if (!err) {
            this.loading = true;

            for (var i in this.company_credit_cards) {
              if (this.company_credit_cards[i].id == this.cardId) {
                this.company_credit_cards[i].id = this.cardId;
                this.company_credit_cards[i].person_type = values.person_type;
                this.company_credit_cards[i].cnpj = values.cnpj;

                this.company_credit_cards[i].company_name = values.company_name;
                this.company_credit_cards[i].trading_name = values.trading_name;
                this.company_credit_cards[i].person_type = values.person_type;
                this.company_credit_cards[i].cep = values.cep;
                this.company_credit_cards[i].location_street =
                  values.location_street;
                this.company_credit_cards[i].location_number =
                  values.location_number;
                this.company_credit_cards[i].location_complement =
                  values.location_complement;
                this.company_credit_cards[i].location_neighborhood =
                  values.location_neighborhood;
                this.company_credit_cards[i].location_city =
                  values.location_city;
                this.company_credit_cards[i].location_state =
                  values.location_state;
                this.company_credit_cards[i].bank_id = values.bank_id;
                this.company_credit_cards[i].card_number = values.card_number;
                this.company_credit_cards[i].card_month = values.card_month;
                this.company_credit_cards[i].card_year = values.card_year;
                this.company_credit_cards[i].card_cvv = values.card_cvv;
                this.company_credit_cards[i].card_limit = values.card_limit;
                this.company_credit_cards[i].card_name = values.card_name;
                this.company_credit_cards[i].first_name = values.first_name;
                this.company_credit_cards[i].last_name = values.last_name;
                this.company_credit_cards[i].cpf = values.cpf;
                this.company_credit_cards[i].rg = values.rg;
                this.company_credit_cards[i].doc_emission = values.doc_emission;
                this.company_credit_cards[i].doc_emission_state =
                  values.doc_emission_state;
                this.company_credit_cards[i].birthday = values.birthday;
              }
            }

            this.$http
              .post("/option/update", {
                id: 8,
                value: JSON.stringify(this.company_credit_cards),
              })
              .then(() => {
                this.$message.success("Atualizado com sucesso!");
                this.loading = false;
              })
              .catch(() => {
                this.$message.error("Não foi possível atualizar.");
                this.loading = false;
              });
          }
        }
      });
    },
  },
};
</script>
